<!--
	@component:	ANZINPUTFIELD
	@desc:		This component emit an event to the parent on focus
				to disable a button inside of the same wrapper component like ANZGROUPHEADLINE
	@author: 	Yannick Herzog, info@hit-services.net
	@modified:	2020-03-30
 -->
<template>
  <el-form-item :label="data.Lanqkey01">
    <el-input v-model="value" :placeholder="data.Lanqkey01" @blur="onBlur" @focus="onFocus"></el-input>

    <!-- Error -->
    <div v-if="message" class="pos-r" style="min-height: 2rem">
      <p class="el-form-item__error" style="top: auto; margin-top: 0.2rem">
        <span v-html="message"></span>
      </p>
    </div>

    <!-- Debug -->
    <DebugItem v-if="debug.visible" :data="data"></DebugItem>
  </el-form-item>
</template>

<script>
import { get } from 'lodash';
import DebugItem from '@/components/DebugItem';
import mixins from '@/shared/mixins/index';

export default {
  name: 'ANZINPUTFIELD',

  mixins: [mixins],

  components: {
    DebugItem
  },

  props: {
    data: {
      type: Object,
      required: true
    },

    updateNodeKey: {
      type: String,
      required: false
    },

    type: {
      type: String,
      default: 'default'
    }
  },

  computed: {
    InputValue() {
      return get(this.data, 'InputValue', '');
    },

    visibleControl() {
      return this.visibleControls[0].toLowerCase();
    },

    visibleControls() {
      return this.$store.state.visibleControls;
    },

    Xnodekey() {
      return get(this.data, 'Xnodekey');
    }
  },

  data() {
    return {
      message: '',
      oldValue: this.value,
      value: this.data.InputValue
    };
  },

  methods: {
    onBlur() {
      this.$emit('button-state', { disabled: false });

      if (this.value === this.oldValue) {
        return;
      } else {
        this.oldValue = this.value;
      }

      if (this.updateNodeKey) {
        this.$store.commit('pages/SET_PAGES_LOADING', { loading: true, nodeKey: this.updateNodeKey });

        this.requestNode(this.Xnodekey, this.value)
          .then(response => {
            const WebMessage = get(response, 'data.RESPONSE.WebMessage');
            this.$store.commit('pages/HIDE_LOADING');

            // Show error message
            if (WebMessage) {
              this.message = WebMessage;
            }
          })
          .catch(() => {
            this.$store.commit('pages/HIDE_LOADING');

            this.$notify.error({
              title: this.$t('ERROR'),
              message: this.$t('ERROR_DURING_REQUEST'),
              duration: 8000
            });
          });
      }
    },

    onFocus() {
      this.$emit('button-state', { disabled: true });
    }
  }
};
</script>
